import { graphql, PageProps } from "gatsby"
import React from "react"
import { useTemplateType } from "../components/layout/context"
import PageBuilder from "../components/pageBuilder"
import SEO from "../components/SEO"
import { TemplatePageQueryQuery } from "../__generated__/graphql-gatsby"

type IProps = PageProps<TemplatePageQueryQuery>

const Page: React.FC<IProps> = ({ data: { wpPage } }) => {
  const [, setTemplate] = useTemplateType()
  setTemplate("page")

  const { page_builder, seo, uri } = { ...wpPage }

  return (
    <>
      <SEO
        title={seo?.title}
        description={seo?.metaDesc}
        ogTitle={seo?.opengraphTitle}
        ogDescription={seo?.opengraphDescription}
        img={{
          url: seo?.opengraphImage?.sourceUrl,
          alt: seo?.opengraphImage?.altText,
          width: seo?.opengraphImage?.mediaDetails?.width,
          height: seo?.opengraphImage?.mediaDetails?.height,
        }}
        siteName={seo?.opengraphSiteName}
        canonical={uri}
      />
      <PageBuilder blocks={page_builder?.pageBuilder} />
    </>
  )
}

export default Page

export const query = graphql`
  fragment SEO on WpPostTypeSEO {
    metaDesc
    title
    opengraphSiteName
    opengraphTitle
    opengraphDescription
    opengraphImage {
      sourceUrl
      altText
      mediaDetails {
        height
        width
      }
    }
  }

  fragment FormFieldNode on WpFormField {
    __typename
    type
    id
    fieldId
    fieldLabel
    required
    ... on WpTextboxField {
      __typename
      placeholder
      default
    }
    ... on WpTextareaField {
      __typename
      placeholder
      default
    }
    ... on WpSubmitField {
      __typename
      processingLabel
    }
    ... on WpPhoneField {
      __typename
      placeholder
    }
    ... on WpEmailField {
      __typename
      placeholder
    }
    ... on WpDateField {
      __typename
      dateFormat
    }
  }
  fragment Form on WpForm {
    __typename
    formId
    title
    showTitle
    hideComplete
    clearComplete
    addSubmit
    wpFields {
      nodes {
        ...FormFieldNode
      }
    }
  }

  fragment PostLinks on WpPostObjectUnion {
    ... on WpPage {
      uri
    }
    ... on WpProduct {
      uri
    }
    ... on WpNieuws {
      uri
    }
    ... on WpPost {
      uri
    }
    ... on WpMediaItem {
      uri
    }
    ... on WpCarrousel {
      uri
    }
  }

  fragment PageBuilder on WpPage_PageBuilder {
    pageBuilder {
      ...PageBuilderBlocks
    }
  }
  fragment Scroller on WpPage_PageBuilder_PageBuilder_Scroller {
    __typename
    kleur
    list {
      img {
        __typename
        altText
        localFile {
          childImageSharp {
            gatsbyImageData(height: 100, placeholder: BLURRED)
          }
        }
      }
    }
  }
  fragment CarrouselHeight on WpCarrousel_CarrouselSettings_Height {
    heightXl
    heightLg
    heightMd
    heightSm
    heightXs
  }
  fragment Carrousel on WpPage_PageBuilder_PageBuilder_Carrousel {
    __typename
    carrousel {
      ... on WpCarrousel {
        id
        carrousel_settings {
          duur
          isAnimated
          isSingle
          randomStart
          overlay
          height {
            ...CarrouselHeight
          }
        }
        carrousel_images {
          images {
            image {
              __typename
              altText
              localFile {
                publicURL
                childImageSharp {
                  gatsbyImageData(width: 1920, placeholder: BLURRED)
                }
              }
            }
          }
        }
      }
    }
  }
  fragment Youtube on WpPage_PageBuilder_PageBuilder_Youtube {
    __typename
    title
    url
    fullWidth
    aspectRatio
    maxHeightPlayer
    maxWidthPlayer
  }
  fragment TekstEditor on WpPage_PageBuilder_PageBuilder_TekstEditor {
    __typename
    tekst
    bgColor
  }
  fragment Afbeelding on WpPage_PageBuilder_PageBuilder_Afbeelding {
    __typename
    maxWidth
    hasPadding
    bgColor
    afbeelding {
      __typename
      altText
      localFile {
        publicURL
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  }
  fragment CTA on WpPage_PageBuilder_PageBuilder_Cta {
    __typename
    text
    buttonText
    uitlijningKnop
    linkKnop {
      ...PostLinks
    }
  }
  fragment ContactBanner on WpPage_PageBuilder_PageBuilder_ContactBanner {
    __typename
    hasAltTekst
    text
  }
  fragment Formulier on WpPage_PageBuilder_PageBuilder_Formulier {
    __typename
    formulier {
      ...Form
    }
  }
  fragment Kaart on WpPage_PageBuilder_PageBuilder_Kaarten_list {
    titel
    type
    linkCategorie {
      id
      uri
    }
    linkPagina {
      ...PostLinks
    }
    color
    img {
      __typename
      altText
      localFile {
        childImageSharp {
          gatsbyImageData(width: 320)
        }
      }
    }
  }
  fragment Kaarten on WpPage_PageBuilder_PageBuilder_Kaarten {
    __typename
    padding
    list {
      ...Kaart
    }
  }

  fragment DoubleLayout_Formulier on WpPage_PageBuilder_PageBuilder_DubbeleLayout_DoubleLayout_Formulier {
    __typename
    formulier {
      ...Form
    }
  }
  fragment DoubleLayout_Img on WpPage_PageBuilder_PageBuilder_DubbeleLayout_DoubleLayout_Img {
    __typename
    bgColor
    circle
    img {
      __typename
      altText
      localFile {
        publicURL
        childImageSharp {
          gatsbyImageData(width: 1920)
        }
      }
    }
  }
  fragment DoubleLayout_Text on WpPage_PageBuilder_PageBuilder_DubbeleLayout_DoubleLayout_Text {
    __typename
    bgColor
    buttonAlignment
    buttonKleur
    buttonText
    buttonLink {
      ...PostLinks
    }
    cta
    text
    bovenUitlijnen
  }
  fragment DoubleLayout_Youtube on WpPage_PageBuilder_PageBuilder_DubbeleLayout_DoubleLayout_Youtube {
    __typename
    title
    url
    fullWidth
    aspectRatio
    maxHeightPlayer
    maxWidthPlayer
  }

  fragment DoubleLayout on WpPage_PageBuilder_PageBuilder_DubbeleLayout {
    __typename
    doubleLayout {
      ... on WpPage_PageBuilder_PageBuilder_DubbeleLayout_DoubleLayout_Formulier {
        ...DoubleLayout_Formulier
      }
      ... on WpPage_PageBuilder_PageBuilder_DubbeleLayout_DoubleLayout_Img {
        ...DoubleLayout_Img
      }
      ... on WpPage_PageBuilder_PageBuilder_DubbeleLayout_DoubleLayout_Text {
        ...DoubleLayout_Text
      }
      ... on WpPage_PageBuilder_PageBuilder_DubbeleLayout_DoubleLayout_Youtube {
        ...DoubleLayout_Youtube
      }
    }
  }

  fragment PageBuilderBlocks on WpPage_PageBuilder_PageBuilder {
    ... on WpPage_PageBuilder_PageBuilder_Scroller {
      ...Scroller
    }
    ... on WpPage_PageBuilder_PageBuilder_Carrousel {
      ...Carrousel
    }
    ... on WpPage_PageBuilder_PageBuilder_Youtube {
      ...Youtube
    }
    ... on WpPage_PageBuilder_PageBuilder_TekstEditor {
      ...TekstEditor
    }
    ... on WpPage_PageBuilder_PageBuilder_Afbeelding {
      ...Afbeelding
    }
    ... on WpPage_PageBuilder_PageBuilder_Cta {
      ...CTA
    }
    ... on WpPage_PageBuilder_PageBuilder_ContactBanner {
      ...ContactBanner
    }
    ... on WpPage_PageBuilder_PageBuilder_Formulier {
      ...Formulier
    }
    ... on WpPage_PageBuilder_PageBuilder_Kaarten {
      ...Kaarten
    }
    ... on WpPage_PageBuilder_PageBuilder_DubbeleLayout {
      ...DoubleLayout
    }
  }

  query TemplatePageQuery($id: String!) {
    wpPage(id: { eq: $id }) {
      id
      uri
      seo {
        ...SEO
      }
      page_builder {
        ...PageBuilder
      }
    }
  }
`
